@import "../../base/variables-colors";
@import "../../base/variables-typography";
@import "../../base/variables-breakpoints";
@import "../../base/variables";
@import "../../base/mixins";

.HeroFull {
    margin: 0 calc(0px - env(safe-area-inset-right)) 0 calc(0px - env(safe-area-inset-left));
    @media print {
        height: auto !important;
    }
    &-Inner {
        position: relative;
        height: 500px;
        height: 100vh;
        max-height: 200vw;
        margin: 0;
        padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
        color: #fff;
        background: #000;
        //background-position: 0 0;
        //background-color: $color-imaloading;
        //background-image: $stripes-imaloading;
        //background-size : $size-imaloading;
        // compensate for 100vh being the maximum screen height not the minumum (which is what you get at paload time) - see the docs 3.2.5
        
        .iphone & {
            max-height: calc(100vh - 75px);
            @media screen AND (min-height:635px) { // iPhone X (The new "home bar" and rounded corners adds quite a bit of chrome);
                max-height: calc(100vh - 114px);
            }
            @media screen AND (orientation:landscape) {
                max-height: calc(100vh - 50px);
            }
        }
        .android-mobile & {
            max-height: calc(100vh - 56px);
        }
        .android-tablet & {
            max-height: calc(100vh - 96px);
        }
        .android-vh-old &,
        .samsung-browser &,
        .ios-chrome & {
            max-height: 100vh;
        }
        //.ipad & {
        //    max-height: calc(100vh - 60px);
        //}
        @media (min-width: $sm-and-up) {
            max-height: calc(100vw * (#{$aspect-ratio-169}*0.01));
        }
        @media print {
            max-height: 50vw !important;
        }
    }
    &-Frame {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
        overflow: hidden;
        background-size: cover;
        background-position: 50% 50%;
        // fade in - see 3.2.6
        @supports (animation: none) {
            opacity: 0;
            transition: opacity 0s;
            animation: fade-in 300ms forwards;
            animation-delay: 2s;
            .no-js &,
            .HeroFull--ready & {
                opacity: 1;
                animation: none;
                transition: opacity 300ms;
            }
        }
    }
    &-Img {
        width: 100%;
        height: auto;
        @supports ( animation:none ) {
            opacity: 0;
            animation: fade-in 1200ms forwards;
            animation-delay: 2000ms;
        }
        @media (orientation:portrait) {
            width: auto;
            height: 100%;
        }
        @supports (object-fit: cover) {
            width: 100%;
            height: 101%;
            object-fit: cover;
            object-position: 50% 50%;
        }
        &.ImaImg--Hidden {
            display: none;
        }
    }
    &-Video {
        position: absolute;
        left: 0;
        top: 0;
        animation-delay: 0ms;
        @media print {
            display: none !important;
        }
    }
    &-Mask {
        position: absolute;
        bottom: -0.5px;
        left: 0;
        right: 0;
        height: 25px;
        @media (min-width: $md-and-up) {
            height: 30px;
        }
        @media (min-width: $lg-and-up) {
            height: 50px;
        }
    }
    &-Main {
        display: table;
        position: relative;
        z-index: 0;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        @media (min-width: $xs-and-up) AND (min-height: $h-sm-and-up) {
            min-height: 400px;
        }
        @media print {
            min-height: 0;
        }
        // fade in - see 3.2.6
        @supports (animation: none) {
            opacity: 0;
            transition: opacity 0s;
            animation: fade-in 300ms forwards;
            animation-delay: 2s;
            .no-js &,
            .HeroFull--ready & {
                opacity: 1;
                animation: none;
                transition: opacity 300ms;
            }
        }
        &-Inner {
            display: table-cell;
            height: 100%;
            padding: 0 $gutter;
            vertical-align: middle;
        }
    }
    &-Title {
        @include typo-smallcaps();
        margin: 0 auto;
        text-align: center;
        font-size: $font-size-lg;
        text-shadow: 0 0 20px rgba(0,0,0,0.4);
        @supports ( animation:none ) {
            opacity: 0;
            animation: fade-in 1000ms forwards;
            animation-delay: 500ms;
        }
        // width controls used to control the line break in the headline
        &--lg {
            max-width: 10em;
        }
        &--md {
            max-width: 8em;
        }
        &--sm {
            max-width: 6em;
        }
        // font-size: std android or bigger (basically not small phones) in portrait
        @media (min-width: 360px) {
            font-size: 3.25rem;
        }
        // font-size: phones portrait
        @media (max-width: $less-than-xs) AND (orientation:portrait) {
            font-size: 2.4rem;
        }
        // font-size: phones landscape
        @media (max-height: $h-less-than-sm) AND (orientation:landscape) {
            font-size: 2.4rem;
        }
        // width controls: mostly phones in landscape, go wide and override any width controls
        @media (max-height: $h-less-than-sm) {
            max-width: 100%;
        }
        line-height: 1;
        &-Logo {
            &-Text {
                display: block;
                height: 0;
                overflow: hidden;
                text-indent: -9999px;
            }
            svg,img {
                display: block;
                width: 80vw;
                height: auto;
                margin: 0 auto;
                max-width: 880px;
                @media (min-width: $sm-and-up) {
                    width: 60vw;
                }
                @media (min-width: $md-and-up) {
                    width: 70vw;
                }
                @media (max-width: $less-than-sm) {
                    width: 276px;
                    max-width: 100%;
                }
            }
        }
    }
    &-Text {
        @include typo-smallcaps();
        display: block;
        max-width: 25em;
        margin: 0.6em auto 0;
        line-height: 24px;
        font-size: 1.1rem;
        letter-spacing: 0.06em !important;
        text-align: center;
        text-shadow: 0 0 15px rgba(0,0,0,0.4);
        @supports ( animation:none ) {
            opacity: 0;
            animation: fade-in 1200ms forwards;
            animation-delay: 800ms;
        }
        @media (min-width: $sm-and-up) AND (min-height: $h-sm-and-up) {
            margin: 1em auto 0;
            font-size: 1.8em;
            font-size: calc( 15px + 1.3vw);
        }
        @media (min-width: $md-and-up) AND (min-height: $h-sm-and-up) {
            font-size: $font-size-xl;
            font-size: calc( 10px + 2vw);
        }
        @media (min-width: $lg-and-up) {
            font-size: 1.75em;
        }
    }
    &-More {
        @include normalize-button;
        display: none;
        position: absolute;
        left: 50%;
        bottom: $gutter*2;
        z-index: 10;
        width: 10em;
        margin: 0 0 0 -5em;
        color: #fff;
        @include typo-serif();
        -webkit-tap-highlight-color: rgba(255,255,255,0);
        outline: none;
        user-select: none;
        cursor: pointer;
        @supports ( animation:none ) {
            opacity: 0;
            animation: fade-in 1000ms forwards;
            animation-delay: 2500ms;
        }
        @media (max-width: $less-than-sm) {
            display: block;
        }
        @media print {
            display: none;
        }
        svg {
            max-width: 120px;
            pointer-events: none;
        }
        &-Icon {
            display: block;
            width: 1.4em;
            height: 1.4em;
            margin: 0.2em auto 0;
            color: transparent;
            @media (min-width: $xs-and-up) {
                margin-top: 0.4em;
            }
            &.disc {
                fill: none;
            }
            &.circle {
                fill: #fff;
            }
        }
    }
}